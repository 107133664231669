import axios from "axios";
window.axios = axios;

import _ from "lodash";
window._ = _;

// import Swiper styles
import Rellax from "rellax";
import AOS from "aos";

import Swiper from "swiper/bundle";
window.Swiper = Swiper;

import algoliasearch from "algoliasearch";
window.algoliasearch = algoliasearch;

import ImageZoom from "js-image-zoom";
window.ImageZoom = ImageZoom;

import Alpine from "alpinejs";
import ui from "@alpinejs/ui";
import mask from "@alpinejs/mask";
import persist from "@alpinejs/persist";
import focus from "@alpinejs/focus";
import collapse from "@alpinejs/collapse";
import intersect from "@alpinejs/intersect";

window.Alpine = Alpine;

Alpine.plugin(ui);
Alpine.plugin(mask);
Alpine.plugin(persist);
Alpine.plugin(focus);
Alpine.plugin(collapse);
Alpine.plugin(intersect);

Alpine.start();

new Rellax(".rellax", {
    // center: true,
    breakpoints: [640, 1024, 1280],
});

AOS.init({
    duration: 1500,
    once: true,
});

document.addEventListener("DOMContentLoaded", function () {

    var lazyVideos = [].slice.call(document.querySelectorAll("video.lazy"));

    if ("IntersectionObserver" in window) {
        var lazyVideoObserver = new IntersectionObserver(function (
            entries,
            observer
        ) {
            entries.forEach(function (video) {
                if (video.isIntersecting) {
                    for (var source in video.target.children) {
                        var videoSource = video.target.children[source];
                        if (
                            typeof videoSource.tagName === "string" &&
                            videoSource.tagName === "SOURCE"
                        ) {
                            videoSource.src = videoSource.dataset.src;
                        }
                    }

                    video.target.load();
                    video.target.classList.remove("lazy");
                    lazyVideoObserver.unobserve(video.target);
                }
            });
        });

        lazyVideos.forEach(function (lazyVideo) {
            lazyVideoObserver.observe(lazyVideo);
        });
    }

    let modalLinks = document.querySelectorAll('a[href*="modal"]');

    modalLinks.forEach(function (link) {
        link.addEventListener("click", (e) => {
            const searchParams = new URLSearchParams(link.search);
            if (searchParams.has("modal") && searchParams.get("modal")) {
                e.stopPropagation();
                e.preventDefault();

                let modalName = searchParams.get("modal");

                const openModalEvent = new Event(`open-${modalName}-modal`, {
                    detail: {},
                    bubbles: true,
                    // Allows events to pass the shadow DOM barrier.
                    composed: true,
                    cancelable: true,
                });

                const closeMenuEvent = new Event(`close-menu`, {
                    detail: {},
                    bubbles: true,
                    // Allows events to pass the shadow DOM barrier.
                    composed: true,
                    cancelable: true,
                });

                window.dispatchEvent(closeMenuEvent);
                window.dispatchEvent(openModalEvent);

                return false;
            }
        });
    });
});
